import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import './Navbar.css';
import artist_logo from '../static/images/ChrisVarvaro_logoOB_white.png'; 
import hamburger from '../static/graphics/hamburger.png'; 
import { Fragment } from 'react';

function Navbar(){

  const [active_pane, setActivePane] = useState("home");
  const [user_has_scrolled, setUserHasScrolled] = useState(false); 
  const [mobileMenu, setMobileMenu] = useState(false); 
  const [openMenu, setOpenMenu] = useState(false); 
  let history = useHistory();

  function checkWindowDimensions(){
    if(window.innerWidth <= 850){
      setMobileMenu(true)
    }else{
      setMobileMenu(false)
    }
  }

  useEffect(() => { // on page load 
    checkWindowDimensions(); 
    window.addEventListener("resize", checkWindowDimensions)
    window.onscroll = () => { //detects scrolling
      if(window.scrollY > 0){
        setUserHasScrolled(true)
      }else{
        setUserHasScrolled(false)
      }
    }
    return () => {
      window.unscroll = null;
      window.removeEventListener("resize", checkWindowDimensions)

    }
  }, [active_pane]) 

  async function handleLinkClick(e){
    e.preventDefault(); 

    setActivePane(e.target.id); 

    if(e.target.id !== "contact"){
      history.push("/")
      let scroll_to = e.target.id; 

      let isLoaded = new Promise(function(resolve, reject){
        if(document.getElementById('home')){
          resolve(true)
        }else{
          reject(false) 
        }
      }); 
      await isLoaded; 
      var home = document.getElementById('biopic-container');
      var about = document.getElementById("about-container"); 
      var releasesperformances = document.getElementById("releasesperformaces-container"); 
      // var stream = document.getElementById("stream-container");

      if(scroll_to === "home"){
        home.scrollIntoView({behavior: "smooth"})
      }else if(scroll_to === "about"){
        about.scrollIntoView({behavior: "smooth"})
      }else if(scroll_to === "releasesperformaces"){
        releasesperformances.scrollIntoView({behavior: "smooth"})
      }
      // else if(scroll_to === "stream"){
      //   stream.scrollIntoView({behavior: "smooth"})
      // }        
      
    }else{
      history.push("/contact"); 
      window.scrollTo(0,0); 
    }

    if(openMenu){
      setOpenMenu(false); 
    }


  }

    const active_style = {
      color: 'white',
      textTransform: 'uppercase'
    }
    const inactive_style = {
      color: 'rgb(110, 110, 110)',
      textTransform: 'uppercase'
    }

    const artist_logo_style = {
      height: '50px',
      marginTop: "10px", 
      marginBottom: "10px",
    }

    const is_scrolling = {
      backgroundColor: "rgba(0, 0, 0)",
      transition: "0.5s", 
      transform: "translate(0, -65px)", 
    }

    const menu_active = {
      visibility: "visible" , 
      backgroundColor: 'rgba(0,0,0,0.9)',
      transition: '0.2s',

    }
    const menu_inactive = {
      visibility: "hidden",
      opacity: "0",  
      backgroundColor: "rgba(0,0,0,0)", 
      transition: "0.2s",
    }

    const mobile_is_scrolling = {
      backgroundColor: "rgba(0,0,0,1)",
      transition: "0.5s", 
    }
    const mobile_not_scrolling = {
      backgroundColor: "rgba(0,0,0,0)"
    }

    const image_white = {
      filter: "invert(1)", 
      transition: "0.5s",
    }



  
		return(<Fragment>
      {mobileMenu? 
      <Fragment>
      <div className = "navbar-container-mobile" style = {user_has_scrolled ? mobile_is_scrolling : mobile_not_scrolling}>
      <h3 className = "navbar-logo-mobile"><img src = {artist_logo} alt = "artists's logo" style = {artist_logo_style}></img></h3>
      <img style = {image_white} className = "hamburger-stack" src = {hamburger} alt = "open menu" onClick = {() => {setOpenMenu(openMenu => !openMenu); console.log("Clicked"); }}></img>
          <ul className = "navbar-navmenu-container-mobile" style = {openMenu ? menu_active : menu_inactive}>
              <li className = "navbar-menulink-mobile">
              <Link style = {active_pane === 'home'? active_style: inactive_style} onClick = {handleLinkClick} id = "home" className = "navbar-routerlink" to = '/'>Home</Link>
            </li>
            <li className = "navbar-menulink-mobile">
              <Link style = {active_pane === 'about'? active_style: inactive_style} onClick = {handleLinkClick} id = "about" className = "navbar-routerlink" to = '/'>About</Link>
            </li>
            <li className = "navbar-menulink-mobile">
              <Link style = {active_pane === 'releasesperformaces'? active_style: inactive_style} onClick = {handleLinkClick} id = "releasesperformaces" className = "navbar-routerlink" to = '/'>Past Projects & Press</Link>
            </li>
            {/* <li className = "navbar-menulink-mobile">
              <Link style = {active_pane === 'stream'? active_style: inactive_style} onClick = {handleLinkClick} id = "stream" className = "navbar-routerlink" to = '/'>Stream</Link>
            </li> */}
            <li className = "navbar-menulink-mobile">
              <Link style = {active_pane === 'contact'? active_style: inactive_style} onClick = {handleLinkClick} id = "contact" className = "navbar-routerlink" to = '/contact'>Contact</Link>
            </li>
          </ul>
      </div>
      </Fragment>
      : 
      <Fragment><div className = "navbar-container" style = {user_has_scrolled ? is_scrolling : null}>
      <h3 className = "navbar-logo"><img src = {artist_logo} alt = "artists's logo" style = {artist_logo_style}></img></h3>
        <ul className = "navbar-navmenu-container">
            <li className = "navbar-menulink">
            <Link style = {active_pane === 'home'? active_style: inactive_style} onClick = {handleLinkClick} id = "home" className = "navbar-routerlink" to = '/'>Home</Link>
          </li>
          <li className = "navbar-menulink">
            <Link style = {active_pane === 'about'? active_style: inactive_style} onClick = {handleLinkClick} id = "about" className = "navbar-routerlink" to = '/'>About</Link>
          </li>
          <li className = "navbar-menulink">
            <Link style = {active_pane === 'releasesperformaces'? active_style: inactive_style} onClick = {handleLinkClick} id = "releasesperformaces" className = "navbar-routerlink" to = '/'>Past Projects & Press</Link>
          </li>
          {/* <li className = "navbar-menulink">
            <Link style = {active_pane === 'stream'? active_style: inactive_style} onClick = {handleLinkClick} id = "stream" className = "navbar-routerlink" to = '/'>Stream</Link>
          </li> */}
          <li className = "navbar-menulink">
            <Link style = {active_pane === 'contact'? active_style: inactive_style} onClick = {handleLinkClick} id = "contact" className = "navbar-routerlink" to = '/contact'>Contact</Link>
          </li>
        </ul>
      </div>
      </Fragment>
      }
			</Fragment>
  );
  }
export default Navbar
