import React, {Fragment} from 'react';
import "./releases_performances_images.css"; 
//import all images here
import p1 from "../static/images/CV1.jpg"; 
import p2 from "../static/images/CV2.jpg"; 
import p3 from "../static/images/CV3.jpg"; 
import p4 from "../static/images/CV4.png";
import p5 from "../static/images/CV5.jpg";
import p6 from "../static/images/CV6.png"; 
import p7 from "../static/images/CV7.jpg"; 
import p8 from "../static/images/CV8.png"; 
import p9 from "../static/images/CV9.jpg"; 
import p10 from "../static/images/CV10.png"; 
import p11 from "../static/images/CV11.jpg"; 
import p12 from "../static/images/CV12.jpg"; 




const RPImages = () => {
    return( 
        <Fragment>
            <div className = "images" id = "images-container">
                <img src = {p1} alt = "Universal Music Group"></img>
                <img src = {p2} alt = "CV2"></img>
                <img src = {p3} alt = "Sony Music"></img>
                <img src = {p4} alt = "CV4"></img>
                <img src = {p5} alt = "CV5"></img>
                <img src = {p6} alt = "The Nocturnal Times"></img>
                <img src = {p7} alt = "Electric"></img>
                <img src = {p8} alt = "Electric"></img>
                <img src = {p9} alt = "Electric"></img>
                <img src = {p10} alt = "Mondo NYC"></img>
                <img src = {p11} alt = "The Recording Academy"></img>
                <img src = {p12} alt = "The Grammys"></img>
              </div>
        </Fragment>
    )
}

export default RPImages; 