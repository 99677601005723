import React, {Fragment} from 'react';
import './Home.css';
import '../components/releases_perfomances_images'; 
//images
import artistbiopic from '../static/images/landing+page+background.jpeg'; 
import RPImages from '../components/releases_perfomances_images';

const Home = () =>{

  return (
    <Fragment>


    <div className = "home-container">
      <div className = "home-biopic" id = "biopic-container">
        <img src = {artistbiopic} alt = "Artist's biopic"></img>
      </div>
      <div className = "home-info">
        <div className = "home-about" id = "about-container">
          <p>
          Chris Varvaro is a producer, mix/mastering engineer and musician located in the New York City area. A graduate of William & Mary, his expertise spans across multiple genres, which have generated work with Billboard charting artists and big names within and outside of the music industry. In 2020, Chris was inducted into The Recording Academy as a voting member, with multiple projects submitted for nomination in 2021. A year later Chris earned his first Grammy Nomination and since then has taken on a leadership role within the Recording Academy. His work has earned high praise from some of the strongest producers and tastemakers in the industry such as Austin Kramer, Lenny Fontana, Beyond The Beats, and many more.
          </p>
          
        </div>
        <div className = "home-releasesperformances" id = "releasesperformaces-container">
          <RPImages/>
        </div>
        {/* <div className = "home-stream" id = "stream-container">
          <p>Be sure to follow me on Spotify, thank you!</p>
          <div className = "home-stream-spotify">
          
          <iframe title = 'latest-release' src="https://open.spotify.com/embed/album/5QQXYy23OksfT9gRKWsYwS?theme=0" width="100%" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
          </div>
        </div> */}
      </div>
      
    </div>
    </Fragment>
  )

};

export default Home 
