import React, {useState} from 'react';
import './Contact.css';
import emailjs from 'emailjs-com';
import p1 from "../static/images/contact+page+background.jpeg"
import down_arrow from "../static/graphics/down_arrow.png"; 

function Contact() {
  const[alert, setAlert] = useState(""); 
  function handleSubmit(e){
    e.preventDefault();
    emailjs.sendForm('service_e1vyoy9', process.env.REACT_APP_EMAIL_TEMPLATE_ID, e.target, process.env.REACT_APP_EMAIL_USER_ID)
    .then(result => {
      setAlert("Message sent successfuly. "); 
      var message_location = document.getElementById("message-container");
      message_location.scrollIntoView({behavior: "smooth"})
    }).catch(result => {
      setAlert("Error sending message. "); 
      var message_location = document.getElementById("message-container");
      message_location.scrollIntoViewIfNeeded({behavior: "smooth"})
    })
  }

  function handleFormScroll(){
    var form = document.getElementById("form-container");
    form.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
  }
  

	return (
	<div className = "contact-container">
    <div className = "header-container">
      <div className = "header-img">
          <img src = {p1} alt = "artist's banner"></img>
      </div>
      <div className = "header-text">
        <h3>CONTACT</h3>
        <p><strong>For all other inquiries, leave a message below or email </strong><a href = "mailto:hello@chrisvarvaromusic.com<" target="blank"><strong>hello@chrisvarvaromusic.com</strong></a></p>

      </div>
      <div className = "down-arrow-img">
        <img onClick = {handleFormScroll} src = {down_arrow} alt = "scroll down indicator"/>
      </div>
    </div>

      <div className = "form-container" id = "form-container">
        <form onSubmit= {handleSubmit} className = "form">
          <div className = "name-input">
            <span className = "name-input-name">
              <label htmlFor = "firstname">First Name</label>  
              <input required autoComplete = "off" name = "firstname" type = "text"  className = "form-input-name"/>
            </span>
            <span className = "name-input-name">
              <label for = "lastname">Last Name</label> 
              <input required autoComplete = "off" name = "lastname" type = "text"  className = "form-input-name"/>
            </span>
          </div>
          <p>Email</p> 
          <input required name = "email" type = "text" className = "form-input-email"/>
          <p>Subject</p> 
          <input required autoComplete = "off" name = "subject" type = "text" className = "form-input-subject"/>
          <p>Message</p> 
          <textarea required autoComplete = "off" rows = "5" name = "message" type = "text" className = "form-input-message"/> <br/>
          <div className = "submit-container">
            <button className = "form-button-submit">Submit</button>
          </div>
        </form>
        <h4 id = "message-container">{alert}</h4>
      </div>
	</div>
	);
};

export default Contact
