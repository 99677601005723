import React from 'react';
import './Footer.css'; 
import facebook from '../static/graphics/facebook.png';
import instagram from '../static/graphics/instagram.png';
import spotify from '../static/graphics/spotify (1).png';
import x from '../static/graphics/x.png';
import calendy from "../static/graphics/calendy.png"

function Footer(){
  const date = new Date(); 
  let year = date.getFullYear(); 
    return(
        <div className = "footer-container">
        <div className = "footer-legal" id = "footer-legal-container">
          <p>© 2015-{year} by Chris Varvaro® All rights reserved.</p>
        </div>
        <div className = "socialmedia-links" id = "socialmedia-links-container">
          <ul>
            <li><a rel="noopener noreferrer" target = "_blank" href = "https://www.facebook.com/chrisvarvaroofficial/"><img src = {facebook} alt = "facebook link"/></a></li>
            <li><a rel="noopener noreferrer" target = "_blank" href = "https://www.instagram.com/chrisvarvaroofficial/"><img src = {instagram} alt = "instagram link"/></a></li>
            <li><a rel="noopener noreferrer" target = "_blank" href = "https://twitter.com/itschrisvarvaro"><img src = {x} alt = "twitter link"/></a></li>
            <li><a rel="noopener noreferrer" target = "_blank" href = "https://open.spotify.com/artist/6TUE6LtJ5fDgFsn6AqaKoT?si=DFSvWOq_SbSlgQDu6PZXWg"><img src = {spotify} alt = "spotify link"/></a></li>
            <li><a rel="noopener noreferrer" target = "_blank" href = "https://calendly.com/chris-varvaromusic"><img src = {calendy} alt = "calendy link"/></a></li>
          </ul>
        </div>
        <div className = "accessibility-links">
          <ul>
            <li><a href = "/">Home</a></li>
            <li><a href = "/contact">Contact</a></li>
          </ul>
        </div>
        </div>
    )
}

export default Footer